/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import './assets/css/variables.scss';
@import './assets/css/dropdown.scss';

$primary: #3348f0;

* {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-padding {
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
}

.neat-title {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #212121;
}

.neat-subtitle {
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #808080;
}

.neat-btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    outline: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &__primary {
        color: #fff;
        background-color: $primary;
        border-color: $primary;
        border-radius: 100px;
    }
    &__secundary {
        background:#F4F8FE !important;
        border-radius: 100px !important;
        color: #3369f0 !important;
        border: 0.648961px solid #3368f0 !important;
        box-shadow: 0px 3.2448px 6.48961px rgba(0, 0, 0, 0.15) !important;
    }
    &__medium {
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
    }
}


.neat-btn:disabled {
    pointer-events: none;
    opacity: 0.35;
}



// métodos de pago


.payment-method-box-big {
    background: linear-gradient(180deg, #3097D3 0%, #3369F0 100%);
    border-radius: 16px;
    width: 320px;
    height: 160px;
    position: relative;
    cursor: pointer;
    margin-bottom: 6px;
    .bank-logo {
      position: absolute; 
      top: 9%; 
      right: 0;
    }
    .brand-logo {
      width: 51px; 
      height: 34px; 
      position: absolute; 
      top: 9%; 
      right: 0;
    }
    .card-brand {
      text-transform: lowercase;
      text-transform: capitalize;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FCFDFF;
      position: absolute; 
      left: 6%; 
      top: 12%;
      max-width: 90px;
      text-align: left;
    }
    .card-type {
      text-transform: capitalize;
      font-weight: 300;
      font-size: 16px;
      line-height: 110%;
      color: #F4F8FE;
      position: absolute; 
      left: 6%; 
      bottom: 12%
    }
    .card-number {
      font-weight: 300;
      font-size: 16px;
      line-height: 110%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #FCFDFF;
      position: absolute; 
      right: 6%; 
      bottom: 12%;
    }
    @media (max-width: 767px) {
      min-width: 270px;
      width: 100%;
      max-width: 320px;
    }
    @media (max-width: 500px) {
      max-width: 100%;
    }
  }
  
  
  .payment-method-box-small {
      border: 3px solid transparent;
      cursor: pointer;
      width: max-content;
      height: 76px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 12px;
      background: linear-gradient(180deg, #3097D3 0%, #3369F0 100%);
      border-radius: 16px;
      .card-data {
          .card-brand {
              font-weight: 600!important;
              font-size: 14px!important;
              line-height: 110%!important;
              text-transform: capitalize!important;
              color: #FCFDFF!important;
              max-width: 90px !important;
              text-align: left !important;
              @media screen and (max-width: 767px) {
                  font-size: 10px!important;
              }
          }
          .card-type {
              font-weight: 300!important;
              font-size: 12px!important;
              line-height: 110%!important;
              text-transform: capitalize!important;
              color: #FCFDFF!important;
              @media screen and (max-width: 767px) {
                  font-size: 10px!important;
              }
          }
          .card-number {
              font-weight: 300!important;
              font-size: 12px!important;
              line-height: 110%!important;
              letter-spacing: 0.03em!important;
              text-transform: uppercase!important;
              color: #FCFDFF!important;        
              @media screen and (max-width: 767px) {
                  font-size: 10px!important;
              }
          }
      }
  }
  
  .banco_estado {
      .payment-method-box-big, .payment-method-box-small {
          background: #616A72 !important;
      }
  }
  
  .banco_de_chile {
      .payment-method-box-big, .payment-method-box-small {
          background: #071B49 !important;
      }
  }
  
  .bci-prepago {
      .payment-method-box-big, .payment-method-box-small {
          background: #613DD2 !important;
      }
  }
  
  .bci {
      .payment-method-box-big, .payment-method-box-small {
          background: #F9D949 !important;
          .card-brand {
              color: #3B4086 !important;
            }
            .card-type {
              color: #3B4086 !important;
            }
            .card-number {
              color: #3B4086 !important;
            }
      }
  }
  
  .santander-prepago {
      .payment-method-box-big, .payment-method-box-small {
          background: #6200A7 !important;
      }
  }
  
  .santander {
      .payment-method-box-big, .payment-method-box-small {
          background: #EC0000 !important;
      }
  }
  
  .falabella {
      .payment-method-box-big, .payment-method-box-small {
          background: #007937 !important;
      }
  }
  
  .itau {
      .payment-method-box-big, .payment-method-box-small {
          background: #DC762D !important;
      }
  }
  
  .scotiabank-redcompra {
      .payment-method-box-big, .payment-method-box-small {
          background: #D43934 !important;
      }
  }
  
  .scotiabank {
      .payment-method-box-big, .payment-method-box-small {
          background: #EC0000 !important;
      }
  }
  
  
  .ripley-prepago {
      .payment-method-box-big, .payment-method-box-small {
          background: #523178 !important;
      }
  }
  
  .ripley {
      .payment-method-box-big, .payment-method-box-small {
          background: #8A4595 !important;
      }
  }
  
  .tricot {
      .payment-method-box-big, .payment-method-box-small {
          background: #5799AB !important;
      }
  }
  
  .tenpo {
      .payment-method-box-big, .payment-method-box-small {
          background: #121212 !important;
      }
  }
  
  .caja_los_heroes {
      .payment-method-box-big, .payment-method-box-small {
          background: #5799AB !important;
      }
  }
  
  .walmart {
      .payment-method-box-big, .payment-method-box-small {
          background: #007DC3 !important;
      }
  }
  
  .mercado_pago {
      .payment-method-box-big, .payment-method-box-small {
          background: #009FE2 !important;
      }
  }
  
  .salcobrand {
      .payment-method-box-big, .payment-method-box-small {
          background: #5799AB !important;
      }
  }
  
  .la_polar {
      .payment-method-box-big, .payment-method-box-small {
          background: #FE0000 !important;
      }
  }
  
  .global {
      .payment-method-box-big, .payment-method-box-small {
          background: #5799AB !important;
      }
  }
  
  .coopeuch {
      .payment-method-box-big, .payment-method-box-small {
          background: #F42534 !important;
      }
  }
  
  .cencosud {
      .payment-method-box-big, .payment-method-box-small {
          background: #095AA5 !important;
      }
  }
  
  .banco_security {
      .payment-method-box-big, .payment-method-box-small {
          background: #792995 !important;
      }
  }
  
  .consorcio {
      .payment-method-box-big, .payment-method-box-small {
          background: #58CADF !important;
      }
  }
  
  .internacional {
      .payment-method-box-big, .payment-method-box-small {
          background: #1364A3 !important;
      }
  }
  
  .bice {
      .payment-method-box-big, .payment-method-box-small {
          background: #396085 !important;
      }
  }
  
  .bbva {
      .payment-method-box-big, .payment-method-box-small {
          background: #5799AB !important;
      }
  }
  
  .abcdin {
      .payment-method-box-big, .payment-method-box-small {
          background: #DB034C !important;
      }
  }
  
  .corpbanca {
      .payment-method-box-big, .payment-method-box-small {
          background: #2E7CC1 !important;
      }
  }
  
  .rappi {
      .payment-method-box-big, .payment-method-box-small {
          background: #121212 !important;
      }
  }
  
  .cdk-overlay-container {
    z-index: 980 !important;
  }

  .blue-500 {
    font-weight: 500;
    color: #3B4086;
  }

  .pointer {
    cursor: pointer;
  }

.mat-hint {
    padding: 1px 0;
}